import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWishListItems, addToWishlist, deleteFromWishlist } from '../apiEndPoint';

// Fetch wishlist items
export const fetchWishlist = createAsyncThunk(
    'wishlist/fetchWishlist',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchWishListItems();
            return response;
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch wishlist items');
        }
    }
);

// Add or remove item from wishlist
export const toggleWishlistItem = createAsyncThunk(
    'wishlist/toggleWishlistItem',
    async ({ user, product, inWishlist, deleteId }, { rejectWithValue }) => {
        try {
            if (inWishlist) {
                await deleteFromWishlist(deleteId);
                return { productId: deleteId, removed: true };
            } else {
                const response = await addToWishlist({ user, products: product });
                return { item: response, removed: false };
            }
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to toggle wishlist item');
        }
    }
);

const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState: {
        items: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWishlist.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWishlist.fulfilled, (state, action) => {
                state.loading = false;
                state.items = action.payload;
            })
            .addCase(fetchWishlist.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(toggleWishlistItem.fulfilled, (state, action) => {
                if (action.payload.removed) {
                    state.items = state.items.filter(
                        (item) => item._id !== action.payload.productId
                    );
                } else {
                    state.items.push(action.payload.item);
                }
            })
            .addCase(toggleWishlistItem.rejected, (state, action) => {
                state.error = action.payload;
            });
    },
});

export default wishlistSlice.reducer;
