import React, { useEffect, useRef } from 'react';
import activationSuccess from './../../assets/AccountSuccess.png';
import logout from './../../assets/logOut.png'
import success from './../../assets/AccountSuccess.png'

const Notification = ({ onClose, text, ok, logOut, onConfirm, deleteItem, addItem, removeItem, removeWishlist, onRemove }) => {
    const signInRef = useRef();

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (signInRef.current && !signInRef.current.contains(e.target)) {
                onClose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50 flex justify-center items-center">
            <div
                ref={signInRef}
                className={`bg-white rounded-3xl shadow-lg max-w-6xl h-auto overflow-y-auto relative p-8 mb-8 ${deleteItem || addItem ? "w-[70%]" : "w-[70%] max-w-6xl"}`}
            >
                {logOut ? (
                    <div className="flex flex-col items-center gap-4">
                        <div className="flex items-center justify-center">
                            <img src={addItem ? success : logout} alt="Account Success" className="pt-8" />
                        </div>
                        <div className='flex flex-col items-center justify-center py-4'>
                            <h3 className='text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold'>Are you sure?</h3>
                            <div className=' text-xs sm:text-sm lg:text-base text-[#848484] lg:py-2 w-[80%] text-center'>
                                {removeWishlist ?
                                    <>
                                        Are you sure you want to remove {removeWishlist} from the wishlist?
                                    </>
                                    :
                                    <>
                                        {addItem ? (
                                            <>
                                                Are you sure you want to add <span className='font-semibold'>{addItem}</span> to your cart?
                                            </>
                                        ) : (
                                            <>
                                                {deleteItem ? (
                                                    <>
                                                        Are you sure you want to delete <span className='font-semibold'>{deleteItem}</span> from your cart?
                                                    </>
                                                ) : (
                                                    <>
                                                        {removeItem ? (
                                                            <>
                                                                Are you sure you want to remove <span className='font-semibold'>{removeItem} </span> from your wish list?
                                                            </>
                                                        ) : (
                                                            <>
                                                                Are you sure you want to log out from your <span className='font-semibold'>MedicaBuyer </span> account?
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>}
                            </div>
                        </div>
                        <div className='flex justify-between items-center gap-4 w-full'>
                            <button onClick={logOut} className={`rounded-full px-2 py-3 border hover:text-white w-full ${addItem ? "border-green-500 text-green-500 hover:bg-green-500" : "border-[#D03030] text-[#D03030] hover:bg-[#D03030]"}`}>
                                {removeWishlist ?
                                    <>Remove</>
                                    :
                                    <>
                                        {deleteItem ? (
                                            <>Delete</>)
                                            : (
                                                <>
                                                    {addItem ? (
                                                        <>Add</>
                                                    ) : (
                                                        <>
                                                            {removeItem ? (
                                                                <>
                                                                    Remove
                                                                </>
                                                            ) : (
                                                                <>
                                                                    LogOut
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                    </>}
                            </button>
                            <button onClick={onClose} className={`rounded-full px-2 py-3 border hover:text-white w-full ${addItem ? "border-green-500 text-green-500 hover:bg-green-500" : "border-[#D03030] text-[#D03030] hover:bg-[#D03030]"}`}>Go Back</button>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center gap-4">
                        <div className='flex items-center gap-4 '>
                            <div className="flex items-center justify-center">
                                <img src={activationSuccess} alt="Account Success" className="size-16" />
                            </div>
                            <div className="w-[80%] text-black text-sm md:text-base lg:text-lg ">
                                {text}
                            </div>
                        </div>
                        <div className="w-[10%] text-sm lg:text-lg flex justify-center">
                            <button
                                type="button"
                                className={`rounded-full font-semibold ${ok ? "text-[#2F94A6] underline" : "text-black "}`}
                                onClick={onClose}
                            >
                                {ok ? ok : "Go Back"}
                            </button>
                        </div>
                    </div >
                )}
            </div >
        </div >
    );
}

export default Notification;
