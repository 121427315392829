import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        createAccountVisible: false,
        signInAccountVisible: false,
        withMobile: false,
        formOpen: false,
        signInStatus: JSON.parse(localStorage.getItem('signInStatus')) || null, // Check localStorage for the status
        signInId: localStorage.getItem('SignIn id') || null,
    },
    reducers: {
        showCreateAccount: (state) => {
            state.createAccountVisible = true;
        },
        hideCreateAccount: (state) => {
            state.createAccountVisible = false;
        },
        showSignInAccount: (state) => {
            state.signInAccountVisible = true;
        },
        hideSignInAccount: (state) => {
            state.signInAccountVisible = false;
        },
        showWithMobile: (state) => {
            state.withMobile = true;
        },
        showWithEmail: (state) => {
            state.withMobile = false;
        },
        showFormOpen: (state) => {
            state.formOpen = true;
        },
        hideFormOpen: (state) => {
            state.formOpen = (false);
        },
        setSignInStatus: (state, action) => {
            state.signInStatus = action.payload; // Set the sign-in status
            localStorage.setItem('signInStatus', JSON.stringify(action.payload)); // Store sign-in status in localStorage
        },
        setSignOutStatus: (state) => {
            state.signInStatus = null;
            localStorage.removeItem('signInStatus'); // Remove sign-in status from localStorage
        },
        setSignInId: (state, action) => {
            state.signInId = action.payload; // Set the sign-in status
            localStorage.setItem('signInId', JSON.stringify(action.payload)); // Store sign-in status in localStorage
        },
        setSignOutId: (state) => {
            state.signInId = null;
            localStorage.removeItem('signInId'); // Remove sign-in status from localStorage
        },
    },
});

export const { showCreateAccount, hideCreateAccount, showSignInAccount, hideSignInAccount, showWithEmail, showWithMobile, showFormOpen, hideFormOpen, setSignInStatus, setSignOutStatus } = modalSlice.actions;
export default modalSlice.reducer;