import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProductBySubCategory,
  fetchProducts,
  fetchSubCategories,
} from "../../apiEndPoint";
import {
  fetchSubCategoriesAsync,
  setInitialTitle,
} from "../../reduxStore/subCategorySlice";
import {
  fetchProductsAsync,
  fetchProductsBySubCategoryAsync,
  setFilteredProductsList,
} from "../../reduxStore/productSlice";

import Category from "../Category";
import SubCategoryComponent from "./SubCategoryComponent";
import CommonBigOffer from "../bigOffer/CommonBigOffer";
import mriMachine from "./../../assets/mriMachine.png";
import shopByCategory from "./shopByCategoryString";
import ChildCategoryComponent from "./ChildCategoryComponent";
import Breadcrumbs from "../../Breadcrumbs";

const SubCategory = () => {
  const location = useLocation();
  const [visibleCategories, setVisibleCategories] = useState([]); // State to manage visible categories
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [initialSubCategory, setInitialSubCategory] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const { categoryName, categoryId } = location.state || {};
  const categories = useSelector((state) => state.categories.items);
  const dispatch = useDispatch();
  const {
    items: products,
    loading,
    error,
  } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(fetchProductsAsync());
    console.log(products);
  }, [dispatch]);


  // const [isLoading, setIsLoading] = useState(true); // Add loading state

  // Filtered list of subcategories by selected category name
  // const filteredItems = subCategories.filter(
  //   (subCategory) => subCategory.category === initialTitle
  // );

  // Fetch initial data
  // useEffect(() => {
  //   dispatch(fetchSubCategoriesAsync());
  //   dispatch(fetchProductsAsync());
  // }, [dispatch]);


  useEffect(() => {
    if (selectedSubCategoryId) {
      dispatch(fetchProductsBySubCategoryAsync(selectedSubCategoryId));
    }
  }, [dispatch, selectedSubCategoryId]);

  useEffect(() => {
    const calculateCategories = () => {
      // Filter categories by parentCategory and set visibleCategories
      const filteredCategories = categories.filter((category) =>
        category.parentCategory && category.parentCategory.name === categoryName
      );
      setVisibleCategories(filteredCategories);

      // Set initialSubCategory to the name of the first category
      if (filteredCategories.length > 0) {
        setInitialSubCategory(filteredCategories[0].name);
        setSelectedSubCategoryId(filteredCategories[0]._id);
      }
    };

    calculateCategories();
  }, [categories, categoryId]);

  useEffect(() => {
    const calculateProducts = () => {
      const filteredProducts = products.filter((item) =>
        item.category && item.category.name === initialSubCategory
      );
      setVisibleProducts(filteredProducts);
    };

    calculateProducts();
  }, [products, initialSubCategory]);

  const handleCategoryClick = (subCategory) => {
    setSelectedSubCategoryId(subCategory._id);
    setInitialSubCategory(subCategory.name);
  };

  return (
    <>
      {/* <Category /> */}
      <Breadcrumbs />
      <section className="flex justify-center font-poppins">
        <div className="container px-6 py-12 relative">

          {/* Title Section */}
          <div className="w-full flex justify-between items-center">
            <h2 onClick={() => console.log(categoryName)} className="font-semibold text-2xl xl:text-3xl ">
              {`${shopByCategory.subCategoryTitle} ${categoryName}`}
            </h2>
          </div>

          {/* Subcategory Selection */}
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 py-4 gap-4 ">
            {visibleCategories.map((subCategory) => (
              <div
                key={subCategory._id}
                className=""
                onClick={() => handleCategoryClick(subCategory)}
              >
                <SubCategoryComponent
                  id={subCategory._id}
                  img={`${process.env.REACT_APP_BASE_URL}${subCategory.image}`}
                  name={subCategory.name}
                  isSelected={selectedSubCategoryId === subCategory._id}
                />
              </div>
            ))}
          </div>

          {/* Products List */}
          <div className="py-20">
            <div className="w-full flex justify-between items-center">
              <div>
                <h2 onClick={() => console.log(products)} className="font-semibold text-2xl xl:text-3xl ">
                  {initialSubCategory}
                </h2>
                <div className="text-[#848484] text-xs md:text-sm lg:text-base">
                  {shopByCategory.desc}
                </div>
              </div>
            </div>

            <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 py-4 gap-4 ">
              {/* Child Categories */}
              {/* {visibleChildCategories.map((childCategory) => (
                <div key={childCategory._id} className="">
                  <ChildCategoryComponent
                    id={childCategory._id}
                    img={`${process.env.REACT_APP_BASE_URL}${childCategory.image}`}
                    name={childCategory.name}
                    isSelected={false}
                  />
                </div>
              ))} */}
              {visibleProducts.length > 0 ? (
                visibleProducts.map((product) => (
                  <CommonBigOffer
                    key={product._id}
                    img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                    name={product.name}
                    parentCategoryName={product.category.name}
                    price={product.variation[0].price}
                    off={`${product.variation[0].discount}% off`}
                    data={shopByCategory.medicalDeviceArray.thermoMeter}
                    dataOrg={product}
                  />
                ))
              ) : (
                <p>No products found</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubCategory;
