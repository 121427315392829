import React from 'react'
import HeroSlider from '../components/HeroSlider.jsx';
import FourFunction from '../components/fourFunction/FourFunction.jsx';
import BestSeller from '../components/bestSeller/BestSeller.jsx'
import BigOffer from '../components/bigOffer/BigOffer.jsx';
import NewArrivals from '../components/newArrivals/NewArrivals.jsx';
import Consumables from '../components/consumables/Consumables.jsx';
import RefurbishedProducts from '../components/refurbishedProducts/RefurbishedProducts.jsx';
import CustomerSays from '../components/customerSays/CustomerSays.jsx';
import AwardsAndRecognition from '../components/awardsRecognition/AwardsAndRecognition.jsx';
import ShopByCategory from '../components/shopByCategory/ShopByCategory.jsx';
import Category from '../components/Category.jsx';


const Home = () => {
    return (
        <>
            <HeroSlider />
            <FourFunction />
            <ShopByCategory />
            <BestSeller />
            <BigOffer />
            <NewArrivals />
            <Consumables />
            <RefurbishedProducts />
            <CustomerSays />
            <AwardsAndRecognition />
        </>
    )
}

export default Home
