import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchProducts as fetchProductsApi,
    fetchProductBySubCategory,
    fetchBigOffer,
    fetchNewArrivals,
    fetchRefurbishedProducts,
} from './../apiEndPoint';

// Async thunks
export const fetchProductsAsync = createAsyncThunk(
    'products/fetchProducts',
    async () => {
        const response = await fetchProductsApi();
        return response;
    }
);

export const fetchBigOfferProducts = createAsyncThunk(
    'products/fetchBigOfferProducts',
    async () => {
        const response = await fetchBigOffer();
        return response;
    }
);

export const fetchNewArrivalProducts = createAsyncThunk(
    'products/fetchNewArrivalProducts',
    async () => {
        const response = await fetchNewArrivals();
        return response;
    }
);

export const fetchRefurbishedProductsSlice = createAsyncThunk(
    'products/fetchRefurbishedProducts',
    async () => {
        const response = await fetchRefurbishedProducts();
        return response;
    }
);

export const fetchProductsBySubCategoryAsync = createAsyncThunk(
    'products/fetchProductsBySubCategory',
    async (subCategoryId) => {
        const response = await fetchProductBySubCategory(subCategoryId);
        return response;
    }
);

export const fetchProductsBySubCategoryAsyncforConsumables = createAsyncThunk(
    'products/fetchProductsBySubCategoryForConsumables',
    async (subCategoryId) => {
        const response = await fetchProductBySubCategory(subCategoryId);
        return response;
    }
);

// Slice
const productSlice = createSlice({
    name: 'products',
    initialState: {
        items: [],
        filteredItems: [],
        filteredItemsForConsumables: [],
        bigOfferItems: [],
        newArrivalItems: [],
        refurbishedItems: [],
        loadingItems: false,
        loadingFilteredItems: false,
        loadingFilteredItemsForConsumables: false,
        loadingBigOfferItems: false,
        loadingNewArrivalItems: false,
        loadingRefurbishedItems: false,
        error: null,
        quantity: 1,
    },
    reducers: {
        setFilteredProductsList: (state, action) => {
            state.filteredItems = action.payload;
        },
        increaseQuantity: (state) => {
            state.quantity += 1;
        },
        decreaseQuantity: (state) => {
            if (state.quantity > 1) {
                state.quantity -= 1;
            }
        },
        resetQuantity: (state) => {
            state.quantity = 1;
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetch Products
            .addCase(fetchProductsAsync.pending, (state) => {
                state.loadingItems = true;
                state.error = null;
            })
            .addCase(fetchProductsAsync.fulfilled, (state, action) => {
                state.loadingItems = false;
                state.items = action.payload;
            })
            .addCase(fetchProductsAsync.rejected, (state, action) => {
                state.loadingItems = false;
                state.error = action.error.message;
            })

            // Fetch Products by SubCategory
            .addCase(fetchProductsBySubCategoryAsync.pending, (state) => {
                state.loadingFilteredItems = true;
                state.error = null;
            })
            .addCase(fetchProductsBySubCategoryAsync.fulfilled, (state, action) => {
                state.loadingFilteredItems = false;
                state.filteredItems = action.payload;
            })
            .addCase(fetchProductsBySubCategoryAsync.rejected, (state, action) => {
                state.loadingFilteredItems = false;
                state.error = action.error.message;
            })

            // Fetch Products for Consumables
            .addCase(fetchProductsBySubCategoryAsyncforConsumables.pending, (state) => {
                state.loadingFilteredItemsForConsumables = true;
                state.error = null;
            })
            .addCase(fetchProductsBySubCategoryAsyncforConsumables.fulfilled, (state, action) => {
                state.loadingFilteredItemsForConsumables = false;
                state.filteredItemsForConsumables = action.payload;
            })
            .addCase(fetchProductsBySubCategoryAsyncforConsumables.rejected, (state, action) => {
                state.loadingFilteredItemsForConsumables = false;
                state.error = action.error.message;
            })

            // Fetch Big Offer Products
            .addCase(fetchBigOfferProducts.pending, (state) => {
                state.loadingBigOfferItems = true;
                state.error = null;
            })
            .addCase(fetchBigOfferProducts.fulfilled, (state, action) => {
                state.loadingBigOfferItems = false;
                state.bigOfferItems = action.payload;
            })
            .addCase(fetchBigOfferProducts.rejected, (state, action) => {
                state.loadingBigOfferItems = false;
                state.error = action.error.message;
            })

            // Fetch New Arrival Products
            .addCase(fetchNewArrivalProducts.pending, (state) => {
                state.loadingNewArrivalItems = true;
                state.error = null;
            })
            .addCase(fetchNewArrivalProducts.fulfilled, (state, action) => {
                state.loadingNewArrivalItems = false;
                state.newArrivalItems = action.payload;
            })
            .addCase(fetchNewArrivalProducts.rejected, (state, action) => {
                state.loadingNewArrivalItems = false;
                state.error = action.error.message;
            })

            // Fetch Refurbished Products
            .addCase(fetchRefurbishedProductsSlice.pending, (state) => {
                state.loadingRefurbishedItems = true;
                state.error = null;
            })
            .addCase(fetchRefurbishedProductsSlice.fulfilled, (state, action) => {
                state.loadingRefurbishedItems = false;
                state.refurbishedItems = action.payload;
            })
            .addCase(fetchRefurbishedProductsSlice.rejected, (state, action) => {
                state.loadingRefurbishedItems = false;
                state.error = action.error.message;
            });
    },
});

export const {
    setFilteredProductsList,
    increaseQuantity,
    decreaseQuantity,
    resetQuantity,
} = productSlice.actions;

export default productSlice.reducer;
