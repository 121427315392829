// store.js
import { configureStore } from '@reduxjs/toolkit';
import accountReducer from './reduxStore/accountSlice';
import modalReducer from './reduxStore/modalSlice';
import profileReducer from './reduxStore/profileSlice';
import kycReducer from './reduxStore/kycSlice'
import cartReducer from './reduxStore/cartSlice'
import contactUsReducer from './reduxStore/contactUsSlice';
import wishlistSlice from './reduxStore/wishlistSlice';
import categoryReducer from './reduxStore/categorySlice';
import subCategoriesReducer from './reduxStore/subCategorySlice'
import productReducer from './reduxStore/productSlice';
import awardsReducer from './reduxStore/awardsSlice';
import chatbotReducer from './reduxStore/chatbotSlice'
import customerReviewsReducer from './reduxStore/customerReviewsSlice';
import { thunk } from 'redux-thunk';

const store = configureStore({
    reducer: {
        account: accountReducer,
        modal: modalReducer,
        profile: profileReducer,
        kyc: kycReducer,
        cart: cartReducer,
        contactUs: contactUsReducer,
        wishlist: wishlistSlice,
        categories: categoryReducer,
        subCategories: subCategoriesReducer,
        products: productReducer,
        awards: awardsReducer,
        customerReviews: customerReviewsReducer,
        chatbot: chatbotReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
