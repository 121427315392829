import React, { useState } from 'react'
import minus from './../../assets/minus.png';
import deleteIcon from './../../assets/Delete.png';
import plus from './../../assets/plus.png';
import { decrementQuantity, deleteCartItem, incrementQuantity, removeItemFromCart } from '../../reduxStore/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import Notification from '../contactUs/Notification';


const CartItems = ({ item, index, itemPrice, quantity }) => {
    const dispatch = useDispatch();
    const { items, loading, error } = useSelector(state => state.cart);

    // const handleIncrement = () => {
    //     dispatch(incrementQuantity());
    // };

    // const handleDecrement = () => {
    //     if (quantity > 1) {
    //         dispatch(decrementQuantity());
    //     }
    // };
    const [confirmDelete, setConfirmDelete] = useState(false);
    const handleButtonClick = (item) => {
        setConfirmDelete(true);
    };
    const handleDeleteProduct = (id) => {
        dispatch(deleteCartItem(id));
    };
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <div key={index} className='rounded-xl bg-[#717171]/10 p-6 h-48'>
            <div className=' grid grid-cols-8 gap-4 h-full '>
                <div className="h-full col-span-2 overflow-hidden">
                    {item.productId?.image?.[0] ? (
                        <img
                            src={`${process.env.REACT_APP_BASE_URL}/${item.productId.image[0]}`}
                            alt="Product"
                            className="h-full w-full object-cover"
                            crossOrigin="anonymous"
                        />
                    ) : (
                        <div className="text-center text-gray-500">Image Not Available</div>
                    )}
                </div>

                <div className='flex w-full justify-between col-span-6 h-full'>
                    <div>
                        <div className='text-sm md:text-base lg:text-lg'>
                            {item.productId?.name || ""}
                        </div>
                        <div className='flex items-center pt-2 gap-1'>
                            <p className='text-[#EF2D2D] font-semibold text-base md:text-lg lg:text-xl'>
                                ₹{itemPrice}
                            </p>
                            <p className='text-[#848484] text-sm md:text-base lg:text-lg line-through'>₹{item.productId.variation[0].price}</p>
                            <p className='text-[#43B678] text-sm md:text-base lg:text-lg'>{`${item.productId.variation[0].discount}% off`}</p>
                        </div>
                        <div className='flex items-center gap-2 p-1 text-[#848484]'>
                            <div className='bg-[#E0F1F6] rounded-lg flex items-center gap-2 p-1'>
                                <div>Qty</div>
                                <div className='flex items-center gap-2'>
                                    <button
                                        //  onClick={() => handleDecrement(item)}
                                        className=''>
                                        <img src={minus} alt="Decrease Quantity" />
                                    </button>
                                    <div>{item.quantity}</div>
                                    <button
                                        // onClick={() => handleIncrement(item)} 
                                        className=''>
                                        <img src={plus} alt="Increase Quantity" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <img src={deleteIcon} alt="Delete Icon" className='cursor-pointer' onClick={handleButtonClick} />
                    </div>
                </div>
                {confirmDelete && (
                    <Notification logOut={() => handleDeleteProduct(item.productId?._id)} onClose={() => setConfirmDelete(false)} deleteItem={item.productId?.name} />
                )}
            </div>
        </div>
    )
}

export default CartItems
