import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../reduxStore/categorySlice';
import { setInitialTitle } from '../reduxStore/subCategorySlice';
import { useNavigate } from 'react-router-dom';
import downArrow from './../assets/downArrow.png';
import zipcode from './../assets/zipCodeIcon.png';

const Category = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [visibleCategories, setVisibleCategories] = useState([]); // State to manage visible categories
    const containerRef = useRef(null); // Reference to the parent container
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const categories = useSelector((state) => state.categories.items);
    const categoryStatus = useSelector((state) => state.categories.status);
    const categoryError = useSelector((state) => state.categories.error);
    const initialTitle = useSelector((state) => state.subCategories.initialTitle);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsOpen((prev) => !prev);
    };

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleSubCategory = useCallback(
        (name, id) => {
            // Only push the selected category to breadcrumb data
            navigate("/subCategory", {
                state: {
                    categoryName: name,
                    categoryId: id,
                    breadcrumbData: [name], // Update breadcrumb with just the selected category
                    resetChain: true, // Reset the breadcrumb chain
                }
            });
            dispatch(setInitialTitle(name)); // Update the initial title with the selected category name
        },
        [navigate, dispatch]
    );

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const calculateVisibleCategories = () => {
            if (!containerRef.current || !categories.length) return;

            const level0Categories = categories.filter(category => category.level === 0); // Filter level 0 categories
            const containerWidth = containerRef.current.offsetWidth; // Get container width
            let totalWidth = 0; // To track the total width of visible categories
            const fullyVisibleCategories = []; // To store categories that fit fully

            level0Categories.forEach((category) => {
                const categoryWidth = 100; // Approximate width of each category button
                if (totalWidth + categoryWidth <= containerWidth) {
                    fullyVisibleCategories.push(category);
                    totalWidth += categoryWidth;
                } else {
                    return; // Stop adding categories if the next one doesn't fit
                }
            });

            setVisibleCategories(fullyVisibleCategories); // Update visible categories
        };

        calculateVisibleCategories(); // Initial calculation

        // Recalculate on window resize
        window.addEventListener("resize", calculateVisibleCategories);
        return () => {
            window.removeEventListener("resize", calculateVisibleCategories);
        };
    }, [categories]);

    useEffect(() => {
        dispatch(fetchCategories());
    }, [dispatch]);

    return (
        <section className="flex justify-center bg-darkBlue text-white font-poppins">
            <div className="container py-8 px-4 md:px-0">
                <div className="flex flex-col gap-2 xl:gap-4 items-center justify-between w-full">
                    <div className="flex items-center w-full">
                        <div
                            onClick={toggleMenu}
                            className="py-1 pr-1 mr-1 border-r-2 text-xs sm:text-sm lg:text-[0.8rem] border-white cursor-pointer"
                        >
                            Categories
                        </div>
                        <div
                            ref={containerRef}
                            className="flex items-center gap-2 w-full overflow-hidden"
                            style={{
                                whiteSpace: "nowrap", // Ensures items stay on a single line
                            }}
                        >
                            {categoryStatus === "loading" && <p>Loading categories...</p>}
                            {categoryError && <p>Error: {categoryError}</p>}
                            {visibleCategories.map((item) => (
                                <div
                                    key={item._id}
                                    onClick={() => handleSubCategory(item.name, item._id)} // Update breadcrumb to the selected category
                                    className="rounded-full text-center text-[0.75rem] xl:text-sm border-[#848484]/50 border-[1px] lg:p-1 px-2 py-1 flex-shrink-0 cursor-pointer"
                                >
                                    {item.name}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex items-center gap-1 2xl:gap-1 text-xs sm:text-sm lg:text-[0.8rem] w-full xl:w-[40%] 2xl:w-[50%] ">
                        <div className="rounded-full border-[#848484]/50 border-[1px] p-1 px-2 sm:px-3 flex items-center justify-center gap-1 sm:gap-2 font-light h-full">
                            Language <img src={downArrow} alt="down arrow" />
                        </div>
                        <div className="rounded-full border-[#848484]/50 border-[1px] p-1 px-2 sm:px-3 flex items-center justify-center gap-1 sm:gap-2 font-light">
                            India <img src={downArrow} alt="down arrow" />
                        </div>
                        <div className="rounded-full border-[#848484]/50 border-[1px] p-1  px-2 md:px-3 flex items-center justify-center w-full gap-1 md:gap-2 bg-[#848484]/50">
                            <img src={zipcode} alt="zip code icon" />
                            <input type="text" placeholder="Enter Zipcode" className="outline-none bg-transparent w-full" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Category;
