import React, { useState } from "react";
import nova from "./../assets/Nova.png";
import ChatbotComponent from "./ChatbotComponent";

const Nova = () => {
    const [openChatbot, setOpenChatbot] = useState(false);

    const handleChatBotToggle = () => {
        setOpenChatbot((prev) => !prev);
    };

    const closeChatBot = () => {
        setOpenChatbot(false);
    };

    return (
        <div className="z-50 fixed -right-4 sm:right-4 -bottom-4 sm:bottom-4 inline-block">
            {/* Chatbot Component (Conditional Rendering) */}
            {openChatbot && (
                <div className="">
                    <ChatbotComponent onRemove={closeChatBot} />
                </div>
            )}
            {/* Nova Image Button */}
            <div
                className=" flex justify-end"
                style={{ zIndex: 1000 }}
            >
                <img
                    src={nova}
                    alt="Nova"
                    onClick={handleChatBotToggle}
                    className="scale-50 sm:scale-75 lg:scale-100 cursor-pointer"
                />
            </div>
        </div>
    );
};

export default Nova;
