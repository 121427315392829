import React, { useCallback, useEffect, useState } from 'react';
import logo from './../assets/logo.png';
import searchIcon from './../assets/searchIcon.png';
import signIn from './../assets/signInIcon.png';
import signUpIcon from './../assets/signUpIcon.png';
import cart from './../assets/cartIcon.png';
import orders from './../assets/ordersIcon.png';
import home from './../assets/home.png'
import help from './../assets/help.png'
import signInProfilePhoto from './../assets/signInProfile.png';
import contactUs from './../assets/contactUsIcon.png';
import header from '../stringFolder/header';
import { useDispatch, useSelector } from 'react-redux';
import { showCreateAccount, showSignInAccount, hideCreateAccount, hideSignInAccount } from '../reduxStore/modalSlice';
import SignIn from './signIn/SignIn';
import { Link, useNavigate } from 'react-router-dom';
import { searchAll } from './../apiEndPoint'; // Import the search API function
import cross from "./../assets/cross.png"
import { fetchCartItems } from '../reduxStore/cartSlice';
import { fetchWishlist } from '../reduxStore/wishlistSlice';

const Header = React.memo(() => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const createAccountVisible = useSelector((state) => state.modal.createAccountVisible);
    const signInAccountVisible = useSelector((state) => state.modal.signInAccountVisible);
    const signInUser = useSelector((state) => state.modal.signInStatus);
    useEffect(() => {
        dispatch(fetchWishlist());
        // console.log(items);
    }, [dispatch]);
    useEffect(() => {
        const storedSignInStatus = localStorage.getItem('SignIn email');
        if (storedSignInStatus) {
            dispatch(signIn(storedSignInStatus)); // Update Redux state with stored sign-in status
        }
    }, [dispatch]);

    const handleSignIn = useCallback(() => {
        dispatch(showSignInAccount());
    }, [dispatch]);

    const handleSignUp = useCallback(() => {
        dispatch(showCreateAccount());
    }, [dispatch]);

    const handleCloseSignin = useCallback(() => {
        dispatch(hideCreateAccount());
        dispatch(hideSignInAccount());
    }, [dispatch]);

    const handleContactUs = () => {
        navigate("/contactUs");
    };

    const handleHome = () => {
        navigate("/");
    };

    const handleMyProfile = () => {
        navigate("/myProfile")
    };

    const handleCart = () => {
        navigate("/cart");
    };

    return (
        <section className='flex justify-center font-poppins'>
            <div className='container px-6 py-8'>
                <div className='flex flex-col gap-2 lg:flex-row items-center justify-between w-full'>
                    <div className='w-[80%] sm:w-[30%] lg:w-[15%]'>
                        <Link to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                    <SearchBar />
                    {signInUser === null ?
                        <MenuItems onSignIn={handleSignIn} onSignUp={handleSignUp} onContactUs={handleContactUs} onCart={handleSignIn} />
                        :
                        <MenuItemsSignIn onHome={handleHome} onMyProfile={handleMyProfile} onCart={handleCart} />
                    }
                </div>
            </div>
            {createAccountVisible && <SignIn onclose={handleCloseSignin} />}
            {signInAccountVisible && <SignIn onclose={handleCloseSignin} />}
        </section>
    );
});

const SearchBar = React.memo(() => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState(''); // State for search text
    const [error, setError] = useState(null);         // State for error messages
    const [debouncedSearchText, setDebouncedSearchText] = useState(''); // State for debounced text
    const [products, setProducts] = useState([]);     // State for storing products
    const [showResults, setShowResults] = useState(false); // State to show/hide results

    // Handle input change and update searchText state
    const handleInputChange = (e) => {
        setSearchText(e.target.value); // Update search text as the user types
        setShowResults(true);          // Show results as the user types
    };

    // Debounce the search text to make API calls after the user stops typing for 500ms
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchText(searchText); // Update debounced search text
        }, 500);

        // Cleanup the timer when searchText changes or component unmounts
        return () => clearTimeout(timer);
    }, [searchText]);

    // Fetch search results when debouncedSearchText changes
    useEffect(() => {
        if (debouncedSearchText) {
            const fetchSearchResults = async () => {
                try {
                    setError(null); // Clear any previous errors
                    const results = await searchAll(debouncedSearchText); // Call the search API
                    setProducts(results); // Store the fetched products
                    setShowResults(true); // Show results
                    console.log('Search results:', results); // Handle results as needed
                } catch (err) {
                    setError('Failed to fetch search results'); // Set error message if API call fails
                    console.error(err);
                }
            };

            fetchSearchResults(); // Fetch results on search text change
        } else {
            setProducts([]); // Clear products if search text is empty
            setShowResults(false); // Hide results if search text is empty
        }
    }, [debouncedSearchText]);

    const handleProductPage = (data) => {
        navigate('/mainProduct', {
            state: {
                data: data
            }
        });
        setShowResults(false); // Hide the results when navigating
    };
    // Clear search text when the cross icon is clicked
    const clearSearchText = () => {
        setSearchText('');      // Clear the search text
        setProducts([]);        // Clear the search results
        setShowResults(false);  // Hide the results
    };

    return (
        <div className='w-[90%] lg:w-[30%] 2xl:w-[35%] relative '>
            <div className='rounded-full border border-black/30 flex items-center justify-between bg-[#F3F4F4] p-2'>
                <img src={searchIcon} alt="search-icon" />
                <input
                    type="text"
                    className='outline-none w-full mx-2 bg-[#F3F4F4]'
                    placeholder="Search Products"
                    value={searchText}
                    onChange={handleInputChange}
                />
                {searchText && (
                    <img
                        src={cross}
                        alt="clear-search-icon"
                        className="cursor-pointer absolute right-3"
                        onClick={clearSearchText}
                    />
                )}
            </div>
            {error && <div className="text-red-500 mt-2">{error}</div>}
            {/* Display product names if any results are fetched */}
            {showResults && products.length > 0 && (
                <div className="absolute top-11 w-full ">
                    <ul className='bg-white p-2 rounded-xl border border-black/25 space-y-1'>
                        {products.map((product, index) => (
                            <li key={index} onClick={() => handleProductPage(product)} className="text-sm lg:text-base cursor-pointer">{product.name}</li> // Render product names
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
});



const MenuItems = React.memo(({ onSignIn, onSignUp, onContactUs, onCart }) => (
    <div className='grid grid-cols-3 md:grid-cols-6 lg:flex items-center justify-end gap-2 sm:gap-3 xl:gap-5 text-xs sm:text-sm xl:text-base w-full lg:w-[55%] 2xl:w-[50%]'>
        <MenuItem icon={signIn} label={header.headerMenu.signIn} onClick={onSignIn} />
        <MenuItem icon={signUpIcon} label={header.headerMenu.signUp} onClick={onSignUp} />
        <MenuItem icon={cart} label={header.headerMenu.cart} onClick={onCart} />
        <MenuItem icon={orders} label={header.headerMenu.orders} />
        <MenuItem icon={contactUs} label={header.headerMenu.contactUs} onClick={onContactUs} />
        <SellWithUsButton />
    </div>
));

const MenuItemsSignIn = React.memo(({ onHome, onMyProfile, onCart }) => (
    <div className='grid grid-cols-3 md:grid-cols-6 lg:flex items-center justify-end gap-1 sm:gap-3 xl:gap-5 text-xs sm:text-sm xl:text-base w-full lg:w-[55%] 2xl:w-[50%]'>
        <MenuItem icon={home} label={header.headerMenu.home} onClick={onHome} />
        <MenuItem icon={cart} label={header.headerMenu.cart} onClick={onCart} cart={"signIn"} />
        <MenuItem icon={orders} label={header.headerMenu.orders} />
        <MenuItem icon={help} label={header.headerMenu.help} />
        <SellWithUsButton />
        <img src={signInProfilePhoto} onClick={onMyProfile} alt="" />
    </div>
));

const MenuItem = React.memo(({ icon, label, onClick }) => {
    const dispatch = useDispatch();
    const { items, loading, error } = useSelector(state => state.cart);
    useEffect(() => {
        dispatch(fetchCartItems());
    }, [dispatch]);
    return (
        <div className='flex items-center gap-1 xl:gap-2 cursor-pointer relative' onClick={onClick}>
            {label === header.headerMenu.cart &&
                <div className='text-white bg-lightBlue rounded-full flex flex-col items-center justify-center w-4 h-4 absolute left-2 -top-2 ' >
                    <div className=' text-[0.65rem]'>
                        {items.items === undefined ? 0 : items.items.length}
                    </div>
                </div>
            }
            <img src={icon} alt={label} onClick={() => console.log(items.items)} />
            <div>{label}</div>
        </div>
    );
});


const SellWithUsButton = React.memo(() => (
    <div className=' col-span-1  text-left'>
        <button className='bg-lightBlue text-white rounded-full p-1 md:p-2 px-1 xl:px-4 '>
            {header.headerMenu.sellWithUs}
        </button>
    </div>
));

export default Header;
