import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import mainProductImage from './../../assets/mainProductImage.png'
import subCategoryImage1 from './../../assets/subProductImage1.png'
import subCategoryImage2 from './../../assets/subProductImage2.png'
import subCategoryImage3 from './../../assets/subProductImage3.png'
import subCategoryImage4 from './../../assets/subProductImage4.png'
import like from "./../../assets/like.svg";
import productPage from './productPageString'
import minus from './../../assets/minus.png'
import plus from './../../assets/plus.png'
import { useDispatch, useSelector } from 'react-redux'
import { addCartItem } from '../../reduxStore/cartSlice'
import alsoBuy1 from './../../assets/alsoBuy1.png'
import alsoBuy2 from './../../assets/alsoBuy2.png'
import alsoBuy3 from './../../assets/alsoBuy3.png'
import alsoBuy4 from './../../assets/alsoBuy4.png'
import alsoBuy5 from './../../assets/alsoBuy5.png'
import alsoBuy6 from './../../assets/alsoBuy6.png'
import CommonBigOffer from '../../components/bigOffer/CommonBigOffer'
import { addToCart, fetchMostSelling, fetchRelatedProducts } from '../../apiEndPoint'
import Notification from '../contactUs/Notification'
import { decreaseQuantity, increaseQuantity, resetQuantity } from '../../reduxStore/productSlice'
import shopByCategory from '../../components/shopByCategory/shopByCategoryString'
import Slider from 'react-slick'
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr'
import Breadcrumbs from '../../Breadcrumbs'

// Custom Arrow Components
const PrevArrow = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="absolute left-0 top-[38%] sm:top-[31%] md:top-[26%] xl:top-[30%] 2xl:top-[32%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
      aria-label="Previous"
    >
      <GrLinkPrevious className='' />
    </button>
  );
};

const NextArrow = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="absolute right-0 top-[38%] sm:top-[31%] md:top-[26%] xl:top-[30%] 2xl:top-[32%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
      aria-label="Next"
    >
      <GrLinkNext />
    </button>
  );
};

const ProductPage = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const dispatch = useDispatch();
  const { items, quantity, status, error } = useSelector((state) => state.products);
  const [relatedProduct, setRelatedProduct] = useState([]);
  const [mostSelling, setMostSelling] = useState([]);
  const productId = data._id;
  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchRelatedProductsData = async (Id) => {
      try {
        const response = await fetchRelatedProducts(Id);
        setRelatedProduct(response);
      } catch (error) {
        console.log(error)
      }
    };
    const fetchMostSellingProducts = async () => {
      try {
        const response = await fetchMostSelling();
        setMostSelling(response);
      } catch (error) {
        console.log(error);
      }
    };
    fetchMostSellingProducts();
    fetchRelatedProductsData(productId);
  }, [])
  const handleIncrement = () => {
    dispatch(increaseQuantity());
  };
  const discPrice = data.variation[0].price - (data.variation[0].price * (data.variation[0].discount / 100));

  const handleDecrement = () => {
    dispatch(decreaseQuantity());
  };

  // Function to extract number from price string and calculate total price
  const calculateTotalPrice = () => {
    // const priceValue = parseInt(discPrice.replace(/[^\d]/g, ''), 10); // Extract number from string
    return discPrice * quantity; // Multiply with quantity
  };
  const [confirmAdd, setConfirmAdd] = useState(false);
  const handleButtonClick = (item) => {
    setConfirmAdd(true);
  };
  const handleAddToCart = (productId, quantity) => {
    dispatch(addCartItem({ productId, quantity }));
    dispatch(resetQuantity()); // Reset the quantity after adding to cart
    setConfirmAdd(false);
    console.log("Success", productId, quantity);
  };
  // Slick slider settings
  const settings = {
    dots: false, // Show dots below the slider
    infinite: true, // Enable infinite loop
    speed: 300, // Animation speed
    slidesToShow: 4, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Autoplay interval in ms
    nextArrow: <NextArrow />, // Custom Next Arrow
    prevArrow: <PrevArrow />, // Custom Prev Arrow
    responsive: [
      {
        breakpoint: 1536, // Screen width <= 1536px
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1280, // Screen width <= 1280px
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024, // Screen width <= 1024px
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // Screen width <= 768px
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640, // Screen width <= 640px
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Breadcrumbs />
      <section className="flex justify-center font-poppins" >
        <div className='container px-6 py-12'>
          <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-6'>
            <div className='grid grid-cols-4 gap-2 h-min'>
              <div className='col-span-1 flex flex-col gap-1 justify-between'>
                <img src={`${process.env.REACT_APP_BASE_URL}/${data.image}`} alt="" crossOrigin="anonymous" />
                <img src={`${process.env.REACT_APP_BASE_URL}/${data.image}`} alt="" crossOrigin="anonymous" />
                <img src={`${process.env.REACT_APP_BASE_URL}/${data.image}`} alt="" crossOrigin="anonymous" />
                <img src={`${process.env.REACT_APP_BASE_URL}/${data.image}`} alt="" crossOrigin="anonymous" />
              </div>
              <div className='col-span-3'>
                <img src={`${process.env.REACT_APP_BASE_URL}/${data.image}`} alt="" className='w-full h-full' crossOrigin="anonymous" />
              </div>
            </div>
            <div>
              <h2 className='text-2xl xl:text-3xl' onClick={() => console.log(data.category.name)}>{data.name}</h2>
              <div className='flex items-center pt-2 gap-1 '>
                <p className='text-[#EF2D2D] font-semibold text-lg md:text-xl lg:text-3xl'>₹{discPrice}</p>
                <p className='text-[#848484] text-base md:text-lg lg:text-2xl line-through'>₹{data.variation[0].price}</p>
                <p className='text-[#43B678] text-base md:text-lg lg:text-2xl'>{`${data.variation[0].discount}% off`}</p>
              </div>
              <div className='py-12'>
                <div className='text-[#848484] text-sm md:text-base lg:text-lg'>{productPage.model}</div>
                <div className='p-4 bg-[#F5F7F7] inline-block font-semibold'>{productPage.modelNo}</div>
              </div>
              <div className='p-4 rounded-xl border-[0.5px] border-[#848484]/25'>
                {/* <div className='py-2'>
                                <div className='text-[#2F94A6] font-semibold text-base lg:text-lg'>{productPage.keyFeature.title}</div>
                                <div>
                                    <ul className='list-disc pl-5'>
                                        {productPage.keyFeature.array.map((item, index) => (
                                            <li key={index} className='text-sm lg:text-base '>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div> */}
                <div className="py-2">
                  <div className="text-[#2F94A6] font-semibold text-base lg:text-lg">
                    {productPage.productDescription}
                  </div>
                  <div className="text-sm lg:text-base">{data.description}</div>
                </div>
                <div className="py-2">
                  <div className="text-[#2F94A6] font-semibold text-base lg:text-lg">
                    {productPage.warranty}
                  </div>
                  <div className="text-sm lg:text-base ">
                    {productPage.warrantyText}
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 rounded-xl border border-[#848484]/25 h-min">
              <div className="text-[#848484] flex items-center justify-between border-b py-4 pb-5 border-[#848484]/25">
                <div>{productPage.cart.quantity}</div>
                <div className="flex items-center gap-2">
                  <button onClick={handleDecrement} className="">
                    <img src={minus} alt="Decrease Quantity" />
                  </button>
                  <div>
                    {quantity} {/* Display the current quantity */}
                  </div>
                  <button onClick={handleIncrement} className="">
                    <img src={plus} alt="Increase Quantity" />
                  </button>
                </div>
              </div>
              <div className="text-[#848484] font-semibold pt-4 pb-12 flex items-center justify-between">
                <div>{productPage.cart.subTotal}</div>
                <div className="text-[#EF2D2D] font-semibold text-base md:text-lg lg:text-2xl">
                  ₹{calculateTotalPrice()}
                </div>
              </div>
              <button
                onClick={handleButtonClick}
                className="border border-[#1A444C]/50 text-[#1A444C] hover:bg-[#1A444C] hover:text-white hover:font-semibold block w-full p-2 rounded-lg my-2"
              >
                {productPage.cart.addToCart}
              </button>
              <button className="border border-[#1A444C]/50 text-[#1A444C] hover:bg-[#1A444C] hover:text-white hover:font-semibold block w-full p-2 rounded-lg my-2">
                {productPage.cart.buyNow}
              </button>
            </div>
            {confirmAdd && (
              <Notification logOut={() => handleAddToCart(data._id, quantity)} onClose={() => setConfirmAdd(false)} addItem={data.name} />
            )}
          </div>
          <div className="w-full py-8">
            <div>
              <h2 className="font-semibold text-2xl xl:text-3xl">
                {productPage.alsoBuyTheseProducts.title}
              </h2>
            </div>
            {/* Slick Slider Section */}
            <div className="py-4 ">
              {relatedProduct.length > 3 ? (
                <Slider {...settings} className='relative '>
                  {relatedProduct.map((product) => (
                    <CommonBigOffer
                      key={product._id}
                      like={like}
                      img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                      name={product.name}
                      price={product.variation[0].price}
                      off={`${product.variation[0].discount}% off`}
                      data={shopByCategory.medicalDeviceArray.thermoMeter}
                      dataOrg={product}
                    />
                  ))}
                </Slider>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {relatedProduct.map((product) => (
                    <CommonBigOffer
                      key={product._id}
                      like={like}
                      img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                      name={product.name}
                      price={product.variation[0].price}
                      off={`${product.variation[0].discount}% off`}
                      data={shopByCategory.medicalDeviceArray.thermoMeter}
                      dataOrg={product}
                    />
                  ))
                  }
                </div>
              )}
            </div>
          </div>
          <div className="w-full py-8">
            <div>
              <h2 className="font-semibold text-2xl xl:text-3xl">
                {productPage.alsoBuyTheseProducts.mostTitle}
              </h2>
            </div>
            {/* Slick Slider Section */}
            <div className="py-4 ">
              {mostSelling.length > 3 ? (
                <Slider {...settings} className='relative '>
                  {mostSelling.map((product) => (
                    <CommonBigOffer
                      key={product._id}
                      like={like}
                      img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                      name={product.name}
                      price={product.variation[0].price}
                      off={`${product.variation[0].discount}% off`}
                      data={shopByCategory.medicalDeviceArray.thermoMeter}
                      dataOrg={product}
                    />
                  ))}
                </Slider>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {mostSelling.map((product) => (
                    <CommonBigOffer
                      key={product._id}
                      like={like}
                      img={`${process.env.REACT_APP_BASE_URL}/${product.image}`}
                      name={product.name}
                      price={product.variation[0].price}
                      off={`${product.variation[0].discount}% off`}
                      data={shopByCategory.medicalDeviceArray.thermoMeter}
                      dataOrg={product}
                    />
                  ))
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductPage;
