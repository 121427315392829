// src/reduxStore/cartSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../apiEndPoint';

// Fetch cart items
export const fetchCartItems = createAsyncThunk('cart/fetchCartItems', async () => {
    const response = await axiosInstance.get('cart');
    return response.data.data; // assuming the data you need is in `data`
});

// Add cart item
export const addCartItem = createAsyncThunk(
    'cart/addCartItem',
    async ({ productId, quantity }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosInstance.post('cart', { productId, quantity });
            dispatch(fetchCartItems()); // Fetch latest cart items after adding an item
            return response.data.data;
        } catch (error) {
            // Silently handle the error without displaying it to the user
            return rejectWithValue('Error adding item to cart');
        }
    }
);

// Delete cart item
export const deleteCartItem = createAsyncThunk(
    'cart/deleteCartItem',
    async (id, { rejectWithValue, dispatch }) => {
        try {
            await axiosInstance.delete(`cart/${id}`);
            dispatch(fetchCartItems()); // Fetch latest cart items after deletion
            return id;
        } catch (error) {
            return rejectWithValue('Error deleting item from cart');
        }
    }
);

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        items: [],
        quantity: 1,
        status: 'idle',
        error: null, // To store error messages silently
    },
    reducers: {
        incrementQuantity: (state) => {
            state.quantity += 1;
        },
        decrementQuantity: (state) => {
            if (state.quantity > 1) state.quantity -= 1;
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetch cart items
            .addCase(fetchCartItems.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCartItems.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload;
            })
            .addCase(fetchCartItems.rejected, (state, action) => {
                state.status = 'failed';
                // Silently handle the fetch error
                state.error = 'Error fetching cart items';
            })
            // Add cart item
            .addCase(addCartItem.fulfilled, (state, action) => {
                // Ensure state.items is an array before proceeding
                if (!Array.isArray(state.items)) {
                    state.items = [];
                }

                const existingItem = state.items.find(item => item.productId === action.payload.productId);
                if (existingItem) {
                    existingItem.quantity += action.payload.quantity;
                } else {
                    state.items.push(action.payload);
                }
            })
            .addCase(addCartItem.rejected, (state, action) => {
                // Log the error for debugging
                console.error('Add cart item error:', action.payload);

                // Silently set the error without showing it to the user
                state.error = null;
            })
            // Delete cart item
            .addCase(deleteCartItem.fulfilled, (state, action) => {
                if (Array.isArray(state.items)) {
                    state.items = state.items.filter(item => item.productId?._id !== action.payload);
                }
            })
            .addCase(deleteCartItem.rejected, (state, action) => {
                console.error('Delete cart item error:', action.payload);
                state.error = null;
            });
    },
});

export const { incrementQuantity, decrementQuantity } = cartSlice.actions;
export default cartSlice.reducer;
