import React, { useEffect } from 'react'
import bestSeller from '../../components/bestSeller/bestSellerString'
import bestSellerQuote from './../../assets/bestSellerQuote.png'
import CommonBigOffer from '../../components/bigOffer/CommonBigOffer'
import like from './../../assets/like (2).png'
import shopByCategory from '../../components/shopByCategory/shopByCategoryString'

const BestSellerPage = ({ title, disc, products }) => {


    return (
        <section className="flex justify-center font-poppins min-h-screen" >
            <div className='container px-6 py-12'>
                <div className='w-full text-center relative'>
                    <div className='absolute -top-4 left-1/2 transform -translate-x-1/2 '>
                        <img src={bestSellerQuote} alt="" className='w-max h-full' />
                    </div>
                    {/* <div className='text-center'> */}
                    <h2 className='font-semibold text-2xl xl:text-3xl' >{title}</h2>
                    <div className='text-[#848484] text-xs md:text-sm lg:text-base'>{disc}</div>
                    {/* </div> */}
                </div>
                <div className="py-12 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 lg:gap-8">
                    {products && products.map((item) => (
                        <CommonBigOffer
                            key={item._id}
                            like={like}
                            // addWishlist={handleAddToWishlist(signInId, product._id)}
                            img={`${process.env.REACT_APP_BASE_URL}/${item.image}`}
                            name={item.name}
                            price={item.variation[0].price}
                            off={`${item.variation[0].discount}% off`}
                            data={shopByCategory.medicalDeviceArray.thermoMeter}
                            dataOrg={item}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default BestSellerPage
