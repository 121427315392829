import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addUserMessage, fetchChatbotResponse } from "./../reduxStore/chatbotSlice";

const ChatbotComponent = ({ onRemove }) => {
    const [input, setInput] = useState("");
    const dispatch = useDispatch();
    const { messages, loading } = useSelector((state) => state.chatbot);

    const chatbotRef = useRef(null); // Ref to the chatbot container
    const inputRef = useRef(null); // Ref to the input field

    const handleSendMessage = () => {
        if (input.trim()) {
            dispatch(addUserMessage(input)); // Add user message to Redux
            dispatch(fetchChatbotResponse(input)); // Fetch bot response
            setInput(""); // Clear input field
        }
    };

    const handleClickOutside = (event) => {
        // Close chatbot if clicked outside
        if (chatbotRef.current && !chatbotRef.current.contains(event.target)) {
            onRemove();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent default Enter key behavior
            handleSendMessage();
        }
    };

    useEffect(() => {
        // Add event listener for clicks
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            ref={chatbotRef}
            style={{
                maxWidth: "400px",
                padding: "10px",
                borderRadius: "8px",
                boxShadow: "4px 8px 12px rgba(0, 0, 0, 0.3)",
            }}
            className="bg-white"
        >
            <h2 className="text-lightBlue font-semibold text-xl text-center pb-2">Chatbot</h2>
            <div
                style={{
                    borderRadius: "8px",
                    padding: "10px",
                    height: "300px",
                    overflowY: "scroll",
                }}
                className="bg-lightBlue"
            >
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        style={{
                            textAlign: msg.type === "user" ? "right" : "left",
                            margin: "5px 0",
                        }}
                    >
                        <span
                            style={{
                                display: "inline-block",
                                padding: "10px",
                                borderRadius: "10px",
                                backgroundColor: msg.type === "user" ? "#DCF8C6" : "#F0F0F0",
                            }}
                        >
                            {msg.text}
                        </span>
                    </div>
                ))}
                {loading && <div>Loading...</div>}
            </div>
            <div style={{ marginTop: "10px", display: "flex" }}>
                <input
                    ref={inputRef}
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown} // Listen for Enter key press
                    style={{
                        flex: 1,
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                    }}
                />
                <button
                    onClick={handleSendMessage}
                    style={{
                        marginLeft: "10px",
                        padding: "10px",
                        backgroundColor: "#5ccc9d",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                    }}
                >
                    Send
                </button>
            </div>
        </div>
    );
};

export default ChatbotComponent;
