import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk to fetch chatbot responses
export const fetchChatbotResponse = createAsyncThunk(
    "chatbot/fetchResponse",
    async (message, { rejectWithValue }) => {
        try {
            const response = await axios.post("http://localhost:5000/chat", {
                message,
            });
            return response.data.reply;
        } catch (error) {
            return rejectWithValue("Error communicating with the Chat API.");
        }
    }
);

const chatbotSlice = createSlice({
    name: "chatbot",
    initialState: {
        messages: [], // Chat history
        loading: false,
        error: null,
    },
    reducers: {
        addUserMessage: (state, action) => {
            state.messages.push({ type: "user", text: action.payload });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchChatbotResponse.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchChatbotResponse.fulfilled, (state, action) => {
                state.loading = false;
                state.messages.push({ type: "bot", text: action.payload });
            })
            .addCase(fetchChatbotResponse.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.messages.push({ type: "bot", text: action.payload });
            });
    },
});

export const { addUserMessage } = chatbotSlice.actions;

export default chatbotSlice.reducer;
