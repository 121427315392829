import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Breadcrumbs = ({ isProductPage = false }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const productBreadcrumbData = location.state?.productDetails || [];
    const productisProductPage = location.state?.isProductPage || false;  // Use a default of false
    const [isProductPageConfirm, setIsProductPageConfirm] = useState(productisProductPage);

    // Initialize breadcrumbs dynamically based on context
    const [categoryState, setCategoryState] = useState(() =>
        isProductPageConfirm
            ? ["Home", productBreadcrumbData?.parentCategory || "Category", productBreadcrumbData?.productName || "Product"]
            : location.state?.breadcrumbData || []
    );

    // Effect to handle breadcrumb updates based on location or product details
    useEffect(() => {
        if (isProductPage || productisProductPage) {  // Check if either `isProductPage` or `productisProductPage` is true
            setIsProductPageConfirm(true); // Set to true if it's a product page
            setCategoryState([
                productBreadcrumbData?.parentCategory || "Category",
                productBreadcrumbData?.productName || "Product",
            ]);
        } else {
            setIsProductPageConfirm(false);
            const newBreadcrumbData = location.state?.breadcrumbData || [];
            const resetChain = location.state?.resetChain || false;

            setCategoryState((prevState) => {
                if (resetChain) {
                    return newBreadcrumbData;
                } else if (newBreadcrumbData.length > 0) {
                    const newCategory = newBreadcrumbData[newBreadcrumbData.length - 1];
                    if (!prevState.includes(newCategory)) {
                        return [...prevState, newCategory];
                    }
                }
                return prevState;
            });
        }
    }, [isProductPage, productBreadcrumbData, location.state?.breadcrumbData, location.state?.resetChain, productisProductPage]);

    const handleBreadcrumbClick = (index) => {
        const selectedCategory = categoryState[index];
        const newBreadcrumbState = categoryState.slice(0, index + 1);

        setCategoryState(newBreadcrumbState);

        if (isProductPage || productisProductPage) {
            navigate('/subCategory', {
                state: {
                    categoryName: selectedCategory,
                    breadcrumbData: newBreadcrumbState,
                    resetChain: false,
                },
            });
        } else {
            navigate('/', { state: { breadcrumbData: newBreadcrumbState } });
            navigate('/subCategory', {
                state: {
                    categoryName: selectedCategory,
                    breadcrumbData: newBreadcrumbState,
                    resetChain: false,
                },
            });
        }
    };

    return (
        <nav className="flex justify-center font-poppins">
            <ul className="container px-6 flex space-x-2 w-[90%] mx-auto py-2">
                {/* Home Link */}
                <li>
                    <Link
                        to="/"
                        className="text-blue-500 hover:underline"
                        onClick={() => console.log(isProductPageConfirm)}
                    >
                        Home
                    </Link>
                </li>
                {/* Dynamic Breadcrumb Links */}
                {categoryState.map((category, index) => {
                    const isLast = index === categoryState.length - 1;
                    return (
                        <li key={index} className="flex items-center">
                            <span className="mx-2">/</span>
                            {isLast ? (
                                <span className="text-gray-500">{category}</span>
                            ) : (
                                <button
                                    onClick={() => handleBreadcrumbClick(index)}
                                    className="text-blue-500 hover:underline"
                                >
                                    {category}
                                </button>
                            )}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default Breadcrumbs;
