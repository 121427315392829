import React, { useEffect, useState } from "react";
import shopByCategory from "./shopByCategoryString";
import CommonShopCategory from "./CommonShopCategory";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../reduxStore/categorySlice";

// Custom Arrow Components
const PrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute left-0 top-[43%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
    aria-label="Previous"
  >
    <GrLinkPrevious />
  </button>
);

const NextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute right-0 top-[43%] transform -translate-y-1/2 bg-white p-3 rounded-full shadow-lg z-10 hover:bg-[#1A444C] text-[#1A444C] hover:text-[#E1DFD7]"
    aria-label="Next"
  >
    <GrLinkNext />
  </button>
);

const ShopByCategory = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      { breakpoint: 1536, settings: { slidesToShow: 5 } },
      { breakpoint: 1280, settings: { slidesToShow: 4 } },
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const categories = useSelector((state) => state.categories.items);
  const [visibleCategories, setVisibleCategories] = useState([]); // State to manage visible categories

  useEffect(() => {
    const calculateVisibleCategories = () => {
      const level0Categories = categories.filter(category => category.level === 0); // Filter level 0 categories
      setVisibleCategories(level0Categories); // Update visible categories
    };

    calculateVisibleCategories(); // Initial calculation

    // Recalculate on window resize
    window.addEventListener("resize", calculateVisibleCategories);
    return () => {
      window.removeEventListener("resize", calculateVisibleCategories);
    };
  }, [categories]);

  return (
    <section className="flex justify-center font-poppins">
      <div className="container px-6 py-12 relative">
        <div className="w-full flex justify-between items-center">
          <div className="w-full">
            <div className="flex items-center justify-between">
              <h2
                className="font-semibold text-2xl xl:text-3xl"
              // onClick={console.log(categories)}
              >
                {shopByCategory.title}
              </h2>
              <button className="underline text-[#1A444C] text-xs md:text-base lg:text-lg xl:text-xl">
                <Link to="/allShopCategory">{shopByCategory.seeAll}</Link>
              </button>
            </div>
            <div className="text-[#848484] text-xs md:text-sm lg:text-base">
              {shopByCategory.desc}
            </div>
          </div>
        </div>

        <div className="py-4">
          {visibleCategories.length > 3 ? (
            <Slider {...settings} className="relative">
              {visibleCategories && visibleCategories.map((item) => {
                return (
                  <CommonShopCategory
                    key={item._id}
                    img={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                    name={item.name}
                  />
                );
              })}
            </Slider>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {visibleCategories.map((item) => (
                <CommonShopCategory
                  key={item._id}
                  id={item._id}
                  img={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                  name={item.name}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ShopByCategory;
