import React, { useEffect } from "react";
import google from './../../assets/Google.png';

function GoogleSignIn() {
    const clientId = "490160897934-8hdmppn4l9ievajdhclr2j7nd27359pp.apps.googleusercontent.com"; // Replace with your Google Client ID

    useEffect(() => {
        // Dynamically load the Google API script
        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;

        script.onload = () => {
            // Initialize Google Sign-In only after the script is loaded
            if (window.google) {
                window.google.accounts.id.initialize({
                    client_id: clientId,
                    callback: handleCallbackResponse,
                });

                // window.google.accounts.id.renderButton(
                //     document.getElementById("googleSignInButton"),
                //     {
                //         theme: "outline",
                //         size: "large",
                //     }
                // );
            }
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleCallbackResponse = (response) => {
        try {
            // Decode JWT response from Google
            const userObject = JSON.parse(atob(response.credential.split(".")[1]));
            console.log("User Info:", userObject);
            alert(`Welcome, ${userObject.name}!`);
        } catch (error) {
            console.error("Error decoding JWT:", error);
        }
    };

    const handleSignOut = () => {
        if (window.google) {
            window.google.accounts.id.disableAutoSelect();
            alert("Signed out successfully!");
        }
    };

    return (
        <div style={{ textAlign: "center" }}>
            <div id="googleSignInButton" className="flex items-center gap-1 ">
                <img src={google} alt="Google Logo" />
                Continue with Google
            </div>
        </div>
    );
}

export default GoogleSignIn;
