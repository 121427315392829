import React, { useCallback, useEffect, useState } from 'react'
import logo from './../assets/logo.png'
import facebook from './../assets/fb.png'
import twitter from './../assets/twitter.png'
import instagram from './../assets/instagram.png'
import linkedIn from './../assets/linkedin.png'
import footer from '../stringFolder/footer'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialTitle } from '../reduxStore/subCategorySlice'

const Footer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const categories = useSelector((state) => state.categories.items);
    const categoryStatus = useSelector((state) => state.categories.status);
    const categoryError = useSelector((state) => state.categories.error);
    const [visibleCategories, setVisibleCategories] = useState([]); // State to manage visible categories
    useEffect(() => {
        const calculateVisibleCategories = () => {
            const level0Categories = categories.filter(category => category.level === 0); // Filter level 0 categories
            setVisibleCategories(level0Categories); // Update visible categories
        };

        calculateVisibleCategories(); // Initial calculation

        // Recalculate on window resize
        window.addEventListener("resize", calculateVisibleCategories);
        return () => {
            window.removeEventListener("resize", calculateVisibleCategories);
        };
    }, [categories]);
    const handleSubCategory = useCallback(
        (name, id) => {
            navigate("/subCategory", { state: { categoryName: name, categoryId: id } });
            dispatch(setInitialTitle(name));
        },
        [navigate, dispatch]
    );
    return (
        <section className="flex justify-center bg-[#1A444C]" >
            <div className='container px-6 py-8'>
                <div className='grid grid-cols-2 lg:grid-cols-5 gap-6 md:gap-4 w-full text-white/50'>
                    <div className=''>
                        <div className='pb-4'>
                            <img src={logo} alt="" className='bg-white' />
                        </div>
                        <ul className='flex flex-col gap-2'>
                            <Link to='/about'>{footer.aboutUs}</Link>
                            <Link to="/contactUs">{footer.contactUs}</Link>
                        </ul>
                    </div>
                    <div>
                        <h3 className='text-white'>{footer.shopByCategory}</h3>
                        <ul>
                            {categoryStatus === "loading" && <p>Loading categories...</p>}
                            {categoryError && <p>Error: {categoryError}</p>}
                            {visibleCategories &&
                                visibleCategories.map((item) => (
                                    <div
                                        key={item._id}
                                        onClick={() => handleSubCategory(item.name, item._id)}
                                        // className="rounded-full text-center text-[0.75rem] xl:text-sm border-[#848484]/50 border-[1px] lg:p-1 px-1 flex items-center justify-center max-h-max w-full font-light cursor-pointer"
                                        className='cursor-pointer'
                                    >
                                        {item.name}
                                    </div>
                                ))}
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <h3 className='text-white'>{footer.sellWithUs}</h3>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <h3 className='text-white'>{footer.policies}</h3>
                            <li>
                                <Link to="/returnAndRefundPolicy" >
                                    {footer.returnpolicy}
                                </Link>
                            </li>
                            <li>
                                <Link to="/privacyPolicy">{footer.privacypolicy}</Link>
                            </li>
                            <li>
                                <Link to="/termsOfUSe">{footer.terms}</Link>
                            </li>
                            <li>
                                <Link to="/shoppingPolicy">{footer.shipping}</Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <h3 className='text-white'>{footer.connectWithUs}</h3>
                            <div className='flex gap-4'>
                                <img src={facebook} alt="facebook" />
                                <img src={twitter} alt="twitter" />
                                <img src={instagram} alt="instagram" />
                                <img src={linkedIn} alt="linkedIn" />
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Footer
