import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEmail, setPassword, toggleNotify, resetForm, setMobile } from '../../reduxStore/accountSlice';
import cross from './../../assets/cross.png';
import signIn from './signInString';
import dottedLine from './../../assets/dottedLine.png';
import mobileIcon from './../../assets/mobile.png';
import google from './../../assets/Google.png';
import eMail from './../../assets/eMail.png'
import VerifyOTP from './VerifyOTP';
import { showWithMobile, showWithEmail, setSignInStatus } from '../../reduxStore/modalSlice'
import ActivationSuccess from './completeProfile/ActivationSuccess';
import { loginUser, registerUser, sendOtp } from './../../apiEndPoint';
import { GoogleLogin } from '@react-oauth/google';
import GoogleSignIn from './GoogleSignIn';
import { useNavigate } from 'react-router-dom';


const SignIn = ({ onclose, activateSuccessForm }) => {
    const signInRef = useRef();
    const nonSignInRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const createAccountVisible = useSelector((state) => state.modal.createAccountVisible);
    const signInAccountVisible = useSelector((state) => state.modal.signInAccountVisible);
    const withMobile = useSelector((state) => state.modal.withMobile);
    const { email, password, notify, mobile } = useSelector(state => state.account);
    const [verifyOTP, setVerifyOTP] = useState(false);
    const [mobileError, setMobileError] = useState('');
    const [errors, setErrors] = useState({ email: '', password: '', mobile: '' });
    const [successScreen, setSuccessScreen] = useState(false);
    const [emailVerify, setEmailVerify] = useState('');
    const [mobileVerify, setMobileVerify] = useState('');

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (signInRef.current && !signInRef.current.contains(e.target)) {
                onclose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [signInRef, onclose]);

    const handleSubmitWithMobile = (e) => {
        e.preventDefault();
        let formErrors = { password: '', mobile: '' };

        // Password validation
        if (!password) {
            formErrors.password = 'Password cannot be empty.';
        }
        // Mobile validation
        const mobileTrimmed = mobile.trim();
        if (mobileTrimmed === '') {
            formErrors.mobile = 'Mobile number is required.';
        } else if (!/^\d{10}$/.test(mobileTrimmed)) {
            formErrors.mobile = 'Mobile number must be exactly 10 digits and contain only numbers.';
        }

        setErrors(formErrors); // Set errors state

        // Check for errors before submitting
        if (!formErrors.password && !formErrors.mobile) {
            console.log("Form Submitted", { mobile, password, notify });

            // Trigger the appropriate state change
            if (createAccountVisible) {
                setVerifyOTP(true);
                dispatch(resetForm());
            } else {
                setSuccessScreen(true);
                dispatch(setSignInStatus('signIn')); // Store "Signin" in localStorage when successScreen is set to true
                dispatch(resetForm());
            }
        }
    };

    const handleSubmitWithEmail = async (e) => {
        e.preventDefault();
        let formErrors = { email: '', password: '' };

        // Email validation
        if (!email.includes('@') || !email.includes('.') || email.length < 5) {
            formErrors.email = 'Please enter a valid email address.';
        }

        // Password validation
        if (!password) {
            formErrors.password = 'Password cannot be empty.';
        }

        setErrors(formErrors);

        // Check for errors before submitting
        if (!formErrors.email && !formErrors.password) {
            console.log("Form Submitted", { email, password, notify });

            const formData = { email, password };

            if (createAccountVisible) {
                try {
                    // Step 2: If OTP is sent successfully, proceed with registration
                    const response = await dispatch(registerUser(formData));

                    // Step 1: Send OTP to the user's email
                    await dispatch(sendOtp(email));
                    setEmailVerify(email);
                    console.log("OTP sent successfully.");

                    // Step 3: Save registration response details to local storage
                    localStorage.setItem("SignIn Email", response.email);
                    localStorage.setItem("SignIn role", response.role);
                    localStorage.setItem("SignIn token", response.token);
                    localStorage.setItem("SignIn id", response._id);
                    console.log(response);

                    setVerifyOTP(true);  // Trigger OTP verification screen
                    dispatch(resetForm());  // Reset the form data

                } catch (error) {
                    // Handle OTP sending or registration error
                    const errorMessage = error.response?.data?.message || 'An error occurred';
                    console.error('Error:', errorMessage);
                    alert(errorMessage);
                }
            } else {
                try {
                    // Dispatch the loginUser action
                    const response = await dispatch(loginUser(email, password));
                    localStorage.setItem("SignIn Email", response.email);
                    localStorage.setItem("SignIn role", response.role);
                    localStorage.setItem("SignIn token", response.token);
                    localStorage.setItem("SignIn id", response._id);
                    console.log(response);

                    setSuccessScreen(true);
                    dispatch(setSignInStatus('signIn'));
                    dispatch(resetForm());
                    navigate("/");
                } catch (error) {
                    // Handle login error
                    const errorMessage = error.response?.data?.message || 'Login failed';
                    console.error('Login error:', errorMessage);
                    alert(errorMessage);
                }
            }
        }
    };

    const handleSuccess = (response) => {
        console.log('Google sign-in successful:', response);
        // Handle successful sign-in (e.g., send token to backend for verification)
    };

    const handleError = () => {
        console.error('Google sign-in error');
    };

    const handleOtpSubmit = (otp) => {
        alert(`Submitted OTP: ${otp}`);
        console.log(email);

        // You can replace the alert with your authentication logic
    };

    const handleWithMobile = () => {
        dispatch(showWithMobile());
        dispatch(resetForm());
    }
    const handleWithEmail = () => {
        dispatch(showWithEmail());
        dispatch(resetForm());
    }
    // Determine if the buttons should be disabled
    const isDisabled = !notify;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex flex-col justify-center items-center z-50">
            <div className='flex justify-end items-end w-[90%] py-1'>
                <button className='bg-white rounded-full p-2' onClick={onclose}>
                    <img src={cross} alt="" className='lg:size-3' />
                </button>
            </div>
            <div ref={verifyOTP ? nonSignInRef : signInRef} className="bg-white rounded-3xl shadow-lg w-[90%] h-auto max-h-[90%] overflow-y-auto relative">
                <div className='bg-[#FFEBEB] text-[#EF2D2D] py-2'>
                    <marquee behavior="" direction="">
                        • Limited time offer: Register now and get 10% off on your first order! • Limited time offer: Up to 20% off on medical equipment!
                        • Limited time offer: Register now and get 10% off on your first order! • Limited time offer: Up to 20% off on medical equipment!
                    </marquee>
                </div>
                <div className='p-4 md:p-8 flex flex-col sm:flex-row gap-8'>
                    <div className='w-full sm:w-[50%] rounded-2xl bg-[#EEF4F5] p-4 md:p-8'>
                        <div>
                            <div className='text-[#2F94A6] font-semibold pb-4'>{signIn.title}</div>
                            <div className='grid grid-cols-2 sm:grid-cols-1 items-center'>
                                {Object.keys(signIn.steps).filter(key => key !== 'title').map((key, index) => (
                                    <div key={index} className='flex gap-2 w-full'>
                                        <div className='w-[10%] flex flex-col items-center'>
                                            <div className='bg-[#1A444C] rounded-full text-white px-2 py-1 flex justify-center items-center'>{key}.</div>
                                            <img src={dottedLine} alt="" className='h-full' />
                                        </div>
                                        <div className='w-[65%] text-left'>
                                            <div className='text-[#1A444C] font-semibold text-sm md:text-base xl:text-lg'>{signIn.steps[key].title}</div>
                                            <div className='text-[#1A444C]/50 text-xs md:text-sm xl:text-base'>{signIn.steps[key].desc}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='w-full sm:w-[50%] flex flex-col justify-between'>
                        <div>
                            <h1 className='font-semibold text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl'>{createAccountVisible ? signIn.createAccount : signIn.signIn}</h1>
                            <div className='text-[#848484] text-sm lg:text-base xl:text-lg'>{signIn.createAccountDesc}</div>
                        </div>
                        {!withMobile ? (
                            <form onSubmit={handleSubmitWithEmail}>
                                <div className='py-2 ' >
                                    <div className='text-[#848484]'>{signIn.email}</div>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => {
                                            dispatch(setEmail(e.target.value));
                                            setErrors(prev => ({ ...prev, email: '' })); // Clear error on input change
                                        }}
                                        placeholder={signIn.emailPlaceHolder}
                                        className={`text-[#848484]/50 bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.email ? 'border border-red-500' : ''}`}
                                    />
                                    {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
                                </div>
                                <div className='py-2'>
                                    <div className='text-[#848484]'>{signIn.password}</div>
                                    <input
                                        type="password"
                                        value={password}
                                        onChange={(e) => {
                                            dispatch(setPassword(e.target.value));
                                            setErrors(prev => ({ ...prev, password: '' })); // Clear error on input change
                                        }}
                                        placeholder={signIn.passwordPlaceHolder}
                                        className={`text-[#848484]/50 bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.password ? 'border border-red-500' : ''}`}
                                    />
                                    {errors.password && <p className="text-red-500 text-xs">{errors.password}</p>}
                                </div>
                                <div className='flex items-center gap-2 pb-4'>
                                    <input
                                        type="checkbox"
                                        checked={notify}
                                        onChange={() => dispatch(toggleNotify())}
                                    />
                                    <p>{signIn.notify}</p>
                                </div>
                                <div className='grid grid-cols-2 gap-4 text-base lg:text-lg'>
                                    <button type="submit" className={`col-span-2 bg-[#1A444C] rounded-full p-4 text-white ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={isDisabled}>
                                        {createAccountVisible ? signIn.createAccount : signIn.signIn}
                                    </button>
                                </div>
                            </form>
                        ) : (
                            <form onSubmit={handleSubmitWithMobile} className=''>
                                <div className='py-2 '>
                                    <div className='text-[#848484]'>{signIn.mobile}</div>
                                    <input
                                        type="mobile"
                                        value={mobile}
                                        onChange={(e) => dispatch(setMobile(e.target.value))}
                                        placeholder={signIn.mobilePlaceHolder}
                                        className='text-[#848484]/50 bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none'
                                    />
                                    {mobileError && <p className="text-red-500 text-sm">{mobileError}</p>}
                                </div>
                                {/* {createAccountVisible ? (
                                    <></>
                                ) : ( */}
                                <div className='py-2'>
                                    <div className='text-[#848484]'>{signIn.password}</div>
                                    <input
                                        type="password"
                                        value={password}
                                        onChange={(e) => {
                                            dispatch(setPassword(e.target.value));
                                            setErrors(prev => ({ ...prev, password: '' })); // Clear error on input change
                                        }}
                                        placeholder={signIn.passwordPlaceHolder}
                                        className={`text-[#848484]/50 bg-[#F3F4F4] rounded-2xl p-4 w-full outline-none ${errors.password ? 'border border-red-500' : ''}`}
                                    />
                                    {errors.password && <p className="text-red-500 text-xs">{errors.password}</p>}
                                </div>
                                {/* )} */}
                                <div className='flex items-center gap-2 pb-4'>
                                    <input
                                        type="checkbox"
                                        checked={notify}
                                        onChange={() => dispatch(toggleNotify())}
                                    />
                                    <p>{signIn.notify}</p>
                                </div>
                                <div className='grid grid-cols-2 gap-4 text-base lg:text-lg'>
                                    <button type="submit" className={`col-span-2 bg-[#1A444C] rounded-full p-4 text-white ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={isDisabled}>
                                        {createAccountVisible ? signIn.createAccount : signIn.signIn}
                                    </button>
                                </div>
                            </form>
                        )}
                        <div className='grid grid-cols-2 gap-4 text-base lg:text-lg my-4'>
                            <button className={`col-span-1 border border-[#1A444C]/50 p-2 xl:p-4 rounded-full flex items-center gap-2 `}
                                onClick={!withMobile ? handleWithMobile : handleWithEmail}>
                                {withMobile ? (
                                    <>
                                        <div><img src={eMail} alt="" /></div>
                                        {createAccountVisible ? signIn.createWithEmail : signIn.signInWithEmail}
                                    </>
                                ) : (
                                    <>
                                        <div><img src={mobileIcon} alt="" /></div>
                                        {createAccountVisible ? signIn.createWithMobile : signIn.signInWithMobile}
                                    </>
                                )}
                            </button>
                            <button className={`col-span-1 border border-[#1A444C]/50 p-2 xl:p-4 rounded-full flex items-center gap-2 
                                                `
                                // ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}

                            } disabled={isDisabled}>
                                <GoogleSignIn />
                            </button>
                        </div>
                        <div className='flex items-center justify-center text-black/50'>
                            {signIn.alreadyAccount}
                            <button className='px-2 text-[#2F94A6]'>{signIn.logIn}</button>
                        </div>
                    </div>
                </div >
            </div >
            {successScreen &&
                <ActivationSuccess title={"Login Success!"} text={"You’ve successfully logged in to your account. Start exploring your website, buy discounted products with hassle-free delivery."} onClose={onclose} />
            }
            {verifyOTP &&
                <VerifyOTP Ref={signInRef} onclose={onclose} length={6} onSubmit={handleOtpSubmit} mobile={mobile} email={emailVerify} activateForm={activateSuccessForm} />
            }
        </div >
    );
};

export default SignIn;
